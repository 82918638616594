<template>
  <div class="teacher">
    <list-template
        :loading="loading"
        :current-page="page"
        :search-config="searchConfig"
        :table-config="tableConfig"
        :table-data="tableData"
        :total="total"
        @onChangePage="changeCurrentPage"
        @onHandle="tableHandle"
        @onReset="search = null"
        @onSearch="onSearch">
    </list-template>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
	_config:{"route":{"path":"list","meta":{"title":"列表","keepAlive":true}}},
  data() {
    return {
      loading:true,
      // 表格搜索配置
      searchConfig: [{
        prop: 'relationship',
        tag: "select",
        placeholder: '请选择关系',
        options: [],
        label: "name",
        value: "id"
      }, {
        prop: 'school_id',
        tag: "select",
        placeholder: '请选择校区',
        options: [],
        label: "name",
        value: "id"
      }, {
        prop: 'mobile_name',
        placeholder: '请输入姓名,电话',
      }],
      // 表格配置
      tableConfig: [{
        prop: 'id',
        label: '序号',
        width: 80
      }, {
        prop: 'parent_name',
        label: '反馈人'
      }, {
        prop: 'student_name',
        label: '关联学生'
      }, {
        prop: 'student_idcard',
        label: '学生身份证'
      }, {
        prop: 'relationship_name',
        label: '与学生关系'
      }, {
        prop: 'shool_name',
        label: '所属校区'
      }, {
        prop: 'mobile',
        label: '联系电话'
      }, {
        prop: 'created_at',
        label: '提交时间'
      }, {
        label: '操作',
        handle: true,
        width: '80',
        buttons: [{ type:"view", text:"详情" }]
      }],
      search: null,
      total: 0,
      // 表格中的数据
      tableData: []
    }
  },
  mounted() {
    this.$store.commit("setPage", 1);
    this.getData()
  },
  activated() {
    this.getData()
  },

  created() {
    this.$_axios2.get('api/mailbox/h5/get-relationship').then(res => {
      for (let dataKey in res.data.data) {
        this.searchConfig[0].options.push({
          name: res.data.data[dataKey],
          id: dataKey
        })
      }
    })
    for (let schoolArrKey in JSON.parse(sessionStorage.getItem('USER_INFO')).school_arr) {
      this.searchConfig[1].options.push({
        name: JSON.parse(sessionStorage.getItem('USER_INFO')).school_arr[schoolArrKey],
        id: schoolArrKey
      })
    }
  },
  computed: {
    ...mapState(["page"])
  },
  methods: {
    // 分页被更改
    changeCurrentPage(e) {
      this.$store.commit("setPage", e);
      this.getData()
    },
    /**
     * 获取数据
     */
    getData() {
      let { search, page } = this;
      this.loading = true;
      // 获取校历数据
      this.$_axios2.get('api/mailbox/pc/get-mail-list', { params: { ...search, page } }).then(res => {
        this.tableData = res.data.data.list
        this.total = res.data.data.page.total
      }).finally(()=>this.loading = false);
    },
    // 搜索按钮被点击
    onSearch(val) {
      this.$store.commit("setPage", 1);
      this.search = val
      this.getData()
    },
    // 表格后面操作被点击
    tableHandle(row) {
      this.$router.push({ path: './details', query: { id: row.id } })
    }
  }
}
</script>

<style lang="scss" scoped>
.list {
  padding: 24rem 0 0 32rem;

  .search {
    .el-input,
    .el-select {
      width: 240rem;
      margin-right: 24rem;
    }
  }

  .pagination {
    margin-top: 50rem;
  }


}
</style>
